<template>
<div :class="tabClass">
  <ul :class="navClass">
    <slot name="link"></slot>
  </ul>
  <div class="tab-content" :style="{'width':vertical?'100%':'auto'}">
    <slot name="content"></slot>
  </div>
</div>
</template>

<script>

export default{
  name:'VTab',
  props:{
    vertical:{
      default:false,
      type:Boolean
    },
    verticalClass:{
      default:'wmin-200',
      type:String
    },
    horizontalClass:{
      default:'nav-tabs-top',
      type:String
    },
    justify:{
      default:false,
      type:Boolean
    },
  },
  computed:{
    verticalAttr(){
      if(!this.vertical) return ""
      var c = "nav-pills flex-column mr-lg-3 "+this.verticalClass+" "
      if(this.justify)
        c += "justify-content-center "
      return c
    },
    horizontalAttr(){
      if(this.vertical) return ""
      var c = "nav-tabs "+this.horizontalClass+" customtab "
      if(this.justify)
        c += "nav-justified "
      return c
    },
    navClass(){
      var a = 'nav '
      a += this.verticalAttr
      a += this.horizontalAttr

      return a
    },
    tabClass(){
      if(!this.vertical) return ""
      return "d-lg-flex justify-content-lg-between";
    }
  }
}
</script>