<template>
<div class="wizard-content">
  <div class="tab-wizard wizard-circle wizard clearfix">
    <div class="steps clearfix">
      <ul role="tablist">
        <slot name="link"></slot>
      </ul>
    </div>
    <div class="content clearfix">
      <slot name="content"></slot>
    </div>
    <div v-if="!isHideButton" class="actions clearfix mt-3">
      <slot name="action">
        <ul>
          <li v-if="step>1" :class="(step==1?'disabled':'')" v-bind:aria-disabled="(step==1?true:false)" @click="doPrev"><button type="button" :class="'btn  btn-rounded btn-previous '+(step==1?'disabled':'')">Sebelumnya</button></li>
          <li v-if="step!=last" aria-hidden="false" aria-disabled="false" @click="doNext"><button type="button" class="btn  btn-rounded btn-info">Selanjutnya</button></li>
          <li v-if="step==last" aria-hidden="false" aria-disabled="false"><button type="submit" class="btn btn-info btn-rounded">Simpan</button></li>
        </ul>
      </slot>
    </div>
  </div>
</div>
</template>

<script>
require('@/assets/css/step.css')
let $ = global.jQuery

export default {
  name:"VStepTab",
  props:{
    step:{
      default:1,
      type:Number
    },
    last:{
      default:1,
      type:Number
    },
    config:{
      default:()=>{
        return {
          disabled:false,
          validate:false,
          refs:{}
        }
      },
      type:Object
    },
    hideButton:{
      default:false,
      type:Boolean
    },
  },
  computed:{
    isHideButton(){return (this.hideButton||false)==true},
  },
  methods:{
    doNext(){
      if(this.config.validate && this.config.refs.VForm){
        this.config.refs.VForm.validate().then(success => {
          if (!success) {
              return;
          }
          this.$emit('update:step',this.step+1);
          $('html, body').animate({scrollTop:0}, '500');
        });
      }else{
        this.$emit('update:step',this.step+1);
        $('html, body').animate({scrollTop:0}, '500');
      }
    },
    doPrev(){
      if(this.step<=1) return;
      this.$emit('update:step',this.step-1);
      $('html, body').animate({scrollTop:0}, '500');
    },
  },
  watch:{
    step(e){
      if(e == this.option)
        this.opened = true
    }
  }
}
</script>
<style scoped>
.btn-previous{
  background-color: #fff;
  color: #54667a;
  border: 1px solid #d9d9d9;
}
</style>