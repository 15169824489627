<template>
<li role="tab" :class="classStep" v-bind:aria-disabled="disabled" v-bind:aria-selected="selected" @click="doClick">
  <a :id="'steps-uid-t-'+option" href="javascript:;" :style="styleLi" v-bind:aria-controls="'steps-uid-p-'+option" class="">
    <span v-if="selected" class="current-info audible">current step: </span>
    <span class="step">{{ option }}</span> <slot></slot>
  </a>
</li>
</template>

<script>

export default{
  name:"VStepLink",
  props:{
    step:{ default:1, type:Number },
    option:{ default:1, type:Number },
    last:{ default:false, type:Boolean },
    disabledClick:{ default:false, type:Boolean },
  },
  data(){
    return {
      opened:false
    }
  },
  mounted() {
    if(this.step == this.option) this.opened = true
  },
  computed:{
    styleLi(){
      return this.disabledClick ? 'cursor:no-drop' : ''
    },
    classStep(){
      var c = ""
      if(this.option==1)
        c += "first "

      if(this.step == this.option)
        c += "current "
      else if(this.step > this.option || this.opened)
        c += "done "
      else if(this.step < this.option || !this.opened)
        c += "disabled "
      
      if(this.last)
        c += "last "
      return c;
    },
    selected(){
      var c = this.classStep;
      return c.includes("current")
    },
    disabled(){
      var c = this.classStep;
      return c.includes("disabled")
    }
  },
  methods:{
    doClick(){
      if(!this.opened || this.disabledClick) return;
      this.$emit('update:step', this.option)
      this.opened = true;
    }
  },
  watch:{
    step(e){
      if(e == this.option)
        this.opened = true
    }
  }
}
</script>