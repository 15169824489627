<template>
<fragment v-if="selected">
  <div :class="contentClass">
    <slot></slot>
  </div>
</fragment>
<fragment v-else></fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'

export default{
  name:'VTabContent',
  components:{Fragment},
  props:{
    step:{
      default:1,
      type:[String,Number]
    },
    option:{
      default:1,
      type:[String,Number]
    }
  },
  computed:{
    contentClass(){
      var a = 'tab-pane fade ';
      if(this.step==this.option)
        a += 'active show '
      return a
    },
    selected(){
      var c = this.contentClass;
      return c.includes("active")
    }
  }
}
</script>